import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Link } from "gatsby"

const WisdomPage = () => {
  let language = "en"

  // active, closed
  let programStatus = "closed"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title="Learn about our Wisdom For Wisdom program"
          description="Follow the Wisdom For Wisdom steps to apply"
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content program-page-wisdom">
          <div className="columns top-section main-wisdom white-back has-text-centered">
            <div className="column">
              <div className="program-banner">
                <img
                  src="https://res.cloudinary.com/nuvolum/image/upload/v1580151307/Programs/wisdom-for-wisdom-badge.svg"
                  className="badge"
                />
              </div>
            </div>
          </div>

          <section className="body-section" style={{ paddingBottom: "80px" }}>
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column">
                <h2 className="has-text-centered">
                  Helping students save money for college expenses!
                </h2>

                <p>
                  At Amarillo Oral & Maxillofacial Surgery & Dental Implants,
                  we’re thrilled to continue supporting local students through
                  the Wisdom For Wisdom program. The program helps students save
                  money for college expenses by providing them with free wisdom
                  teeth removal procedures. This year, our three recipients are
                  Baily, Cole, and Evian!
                </p>

                <p>
                  Bailey, age 18 from Amarillo, just graduated from Amarillo
                  High School. In her application, Bailey said, “School has
                  always been hard for me, because I have never fit into a
                  group. My parents helped me in a tremendous way by getting my
                  teeth fixed with braces and I am forever thankful for that.
                  Both of my parents are teachers and they worked summer school,
                  tutoring jobs, and anything they could for overtime to pay for
                  my dental work, to help me with my self-esteem.” With hard
                  work and as an intern through the AACAL pre-vet program at the
                  Hope Vet Clinic, Bailey has received a partial scholarship to
                  attend ENMU and will study in the pre-vet program.{" "}
                </p>

                <p>
                  Cole, age 17 from Canyon, attends Canyon High School. In his
                  application, he said that things were difficult for his family
                  financially. After having braces, he does not want his wisdom
                  teeth to cause any movement among his other teeth as they come
                  in.
                </p>

                <p>
                  Evian, age 19, lives in Amarillo and attends Amarillo College.
                  In his application, Evian said, “[I was] taught to be grateful
                  and cherish what you have; there are many kids that are much
                  less fortunate than I am. This meant not having fancy new
                  clothes and supplies like other kids throughout my grade
                  school years.” Evian’s boy scout leader inspired him to pursue
                  dentistry and is now taking the first step to become a dental
                  hygienist with hopes of one day becoming a dentist.
                </p>

                <p>
                  We look forward to helping all three students pursue their
                  goals and dreams through education.{" "}
                </p>

                <p>
                  Follow their treatment experiences on{" "}
                  <a
                    href="https://www.facebook.com/thirdmolars/"
                    title="Visit us on Facebook"
                    target="_blank"
                  >
                    Facebook
                  </a>
                  .
                </p>

                <p>
                  Learn more about our{" "}
                  <a
                    href="/procedure/wisdom-teeth-removal-amarillo-tx/"
                    title="Learn about wisdom teeth removal in Amarillo, TX"
                  >
                    wisdom teeth removal procedure
                  </a>
                  .
                </p>

                {/* programStatus === "active" && <h2 className="has-text-centered">The application period is open through April 30. If you’re ready, let’s get started!</h2> */}

                {/* programStatus === "active" && <div style={{textAlign: "center"}}><Button buttonText="Watch Video" href="/wisdom-step-1" /></div> */}

                {/* programStatus === "closed" && <p><strong>Thank you for your interest in our Wisdom For Wisdom program. Our 2020 application period is closed, but stay tuned on our Facebook page to hear about the 2021 program!</strong></p> */}
              </div>
              <div className="column is-4"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default WisdomPage
