import React from "react"
import PropTypes from "prop-types"

var classNames = require("classnames")

class HamburgerIconTheater extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var HamburgerIconTheaterSpanClass = classNames({
      open: this.props.isOpen && !this.props.isClosing
    })

    var cMenuClass = classNames({
      "c-menu-theater": true,
      "c-menu-theater--open": this.props.isOpen,
      disabled: this.props.isOpening || this.props.isClosing
    })

    return (
      <div
        className={cMenuClass}
        onClick={
          this.props.isOpening || this.props.isClosing
            ? false
            : this.props.toggleTheater
        }>
        <span className={HamburgerIconTheaterSpanClass}></span>
        <span className={HamburgerIconTheaterSpanClass}></span>
      </div>
    )
  }
}
HamburgerIconTheater.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleTheater: PropTypes.func
}
export default HamburgerIconTheater
