import PropTypes from "prop-types"
import React from "react"

import VideoButton from "./VideoButton"
import HamburgerIconTheater from "./HamburgerIconTheater.js"
import ResponsivePlayer from "../ResponsivePlayer.js"

var classNames = require("classnames")

const Sidebar = props => {
  var sidebarClass = classNames({
    theater: true,
    "theater--closed": !props.isOpen,
    "theater--open": props.isOpen,
    "theater--closing": props.isClosing
  })

  return (
    <div className={sidebarClass}>
      <div className="theater-content">
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">
            {props.isOpen && (
              <ResponsivePlayer
                url={props.videoUrl}
                controls={props.controls}
                playing={props.playing && !props.isClosing && !props.isOpening}
              />
            )}
          </div>
          <div className="column is-2"></div>
        </div>
      </div>
    </div>
  )
}
Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  videoUrl: PropTypes.string,
  controls: PropTypes.bool,
  playing: PropTypes.bool
}
class TheaterDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidUpdate() {
    if (this.props.isClosing) {
      document.documentElement.classList.remove("theater-is-open")
    } else if (this.props.isOpen) {
      document.documentElement.classList.add("theater-is-open")
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  // Generates keyframe style tag to be document.head.appendChild'ed;
  generateKeyframesStyle(width, height, modifier) {
    let radius = 5000
    if (width > height) {
      radius = width
    } else if (width < height) {
      radius = height
    } else {
      radius = width
    }

    // 2 is too low
    if (!modifier) {
      modifier = 3
    }

    radius = radius * modifier

    return `
    @keyframes menuAnimOpenBottomLeft {
      0% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
      100% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }
    }
    @keyframes menuAnimCloseBottomLeft {
      0% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }
      100% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
    }`
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    const menuKeyframes = this.generateKeyframesStyle(
      window.innerWidth,
      window.innerHeight
    )
    // TO-DO: Check if animation style already exists from a previous video component, if so, do not add another identical style tag to document head
    if (!document.querySelector("#theater-curtain")) {
      const $style = document.createElement("style")
      $style.innerHTML = menuKeyframes
      $style.id = "theater-curtain"
      document.head.appendChild($style)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    let iconColor = "#6d6e6f"
    let iconClass = classNames({
      "theater-icon": true,
      open: this.props.isOpen
    })
    //   if (this.props.atTopOfPage) {
    //   iconColor = "white"
    // }

    return (
      <div className="theater-container">
        <Sidebar
          toggleTheater={this.props.toggleTheater}
          isOpen={this.props.isOpen}
          isClosing={this.props.isClosing}
          isOpening={this.props.isOpening}
          key={this.props.compId}
          videoUrl={this.props.videoUrl}
          controls={this.props.controls}
          playing={this.props.playing}
          compId={this.props.compId}
        />
        {this.props.isOpen && (
          <HamburgerIconTheater
            toggleTheater={this.props.toggleTheater}
            className={iconClass}
            color={iconColor}
            isOpen={this.props.isOpen}
            isClosing={this.props.isClosing}
            isOpening={this.props.isOpening}
            ext={this.props.ext}
            compId={this.props.compId}
          />
        )}
      </div>
    )
  }
}
TheaterDisplay.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleTheater: PropTypes.func,
  videoUrl: PropTypes.string,
  controls: PropTypes.bool,
  playing: PropTypes.bool
}

class TheaterVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: "two-lines",
      position: "right",
      isOpen: false,
      isOpening: false,
      isClosing: false
    }
    this.toggleTheater = this.toggleTheater.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }
  componentDidMount() {
    var menu = document.getElementById("mainNav")
    var pause = document.getElementById("sizzlePause")
    this.menu = menu
    this.pauseButton = pause

    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.isOpen) {
      this.toggleTheater()
    }
  }

  toggleTheater() {
    //todo

    if (this.state.isOpen) {
      if (this.pauseButton) {
        setTimeout(() => {
          this.pauseButton.style.display = "block"
        }, 450)
      }

      this.setState(
        prevState => ({
          ...prevState,
          isClosing: !prevState.isClosing
        }),
        function () {
          setTimeout(() => {
            this.setState(prevState => ({
              ...prevState,
              isOpen: !prevState.isOpen,
              isClosing: !prevState.isClosing
            }))
            this.menu.style.zIndex = "100"
          }, 450)
        }
      )
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    } else if (!this.state.isOpen) {
      this.menu.style.zIndex = "0"
      if (this.pauseButton) {
        this.pauseButton.style.display = "none"
      }
      this.setState(prevState => ({
        ...prevState,
        isOpen: !prevState.isOpen,
        isOpening: !prevState.isOpening
      }))
      setTimeout(() => {
        this.setState(prevState => ({
          ...prevState,
          isOpening: !prevState.isOpening
        }))
      }, 450)
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    }
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { onClick: this.toggleTheater })
    )

    var asWrapper = this.props.asWrapper
    var hasWatchVideo = true
    var onlyButton = false
    var overVideo = false
    var controls = true
    var playing = true
    var sizzleButton = false

    if (this.props.hasWatchVideo === false) {
      hasWatchVideo = false
    }
    if (this.props.onlyButton === true) {
      onlyButton = true
    } else {
      if (this.props.overVideo === true) {
        overVideo = true
      }
    }
    if (this.props.controls === false) {
      controls = false
    }
    if (this.props.playing === false) {
      playing = false
    }
    if (this.props.sizzleButton === true) {
      sizzleButton = true
    }

    if (asWrapper) {
      return (
        <button
          className={`reset block ${
            this.props.wrapperClassName ? this.props.wrapperClassName : ""
          }`}
          type="button"
          tabIndex="0"
          aria-label="open video player"
          onClick={() => this.toggleTheater()}>
          <TheaterDisplay
            toggleTheater={() => {}}
            isOpen={this.state.isOpen}
            isClosing={this.state.isClosing}
            isOpening={this.state.isOpening}
            videoUrl={this.props.videoUrl}
            controls={controls}
            playing={playing}
            compId={this.props.compId}

            //ext={this.extControl}
          />
          <div
            className={
              this.props.childrenWrapperClassName
                ? this.props.childrenWrapperClassName
                : ""
            }>
            {this.props.children}
          </div>
        </button>
      )
    }

    return (
      <>
        <TheaterDisplay
          toggleTheater={this.toggleTheater}
          isOpen={this.state.isOpen}
          isClosing={this.state.isClosing}
          isOpening={this.state.isOpening}
          videoUrl={this.props.videoUrl}
          controls={controls}
          playing={playing}
          compId={this.props.compId}

          //ext={this.extControl}
        />
        {hasWatchVideo && !onlyButton && (
          <div id={this.props.compId} className="image-button-wrapper">
            <VideoButton
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
            />
            {this.props.children}
          </div>
        )}
        {onlyButton && sizzleButton && (
          <div id={this.props.compId}>
            <VideoButton
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
              sizzleButton={sizzleButton}
            />
          </div>
        )}
        {onlyButton && !sizzleButton && this.props.slideButton && (
          <div className="slideVidButton">
            <VideoButton
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
            />
          </div>
        )}
        {onlyButton && !sizzleButton && !this.props.slideButton && (
          <VideoButton
            language={this.props.language}
            toggleTheater={this.toggleTheater}
            overVideo={overVideo}
          />
        )}
        {!hasWatchVideo && childrenWithProps}
      </>
    )
  }
}

TheaterVideo.propTypes = {
  children: PropTypes.element,
  videoUrl: PropTypes.string,
  controls: PropTypes.bool,
  playing: PropTypes.bool,
  overVideo: PropTypes.bool,
  hasWatchVideo: PropTypes.bool,
  onlyButton: PropTypes.bool
}

export default TheaterVideo
